class FormFetchDataHelper {
  /**
   * Helper to Make Ajax Request
   * @param {string} url Target URL
   * @param {object} data Data to be sent as part of request
   * @param {'GET' | 'POST'} request_type
   */

  spinnerCounter = 0;

  #startSpinner() {
    if (this.spinnerCounter < 1) {
      $('#spinner-modal-trigger').trigger('click');
      this.spinnerCounter = 0;
    }
    this.spinnerCounter++;
  }

  #stopSpinner() {
    this.spinnerCounter--;
    if (this.spinnerCounter < 1) $('#spinner-modal button').trigger('click');
  }

  #fetch_request(url, data, request_type = 'GET') {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: request_type,
        url: url,
        dataType: "json",
        data: data,
        error: (xhr, status, error) => {
          const e = "AJAX Error: " + status + error;
          console.error(e);
          reject(e);
        },
        success: (response) => {
          resolve(response);
        }
      });
    });
  }

  get_form_updates(data) {
    // console.log(data);
    url = '/transfer_transactions/get_tt_form_updates'
    return new Promise((resolve, reject) => {
      this.#startSpinner();
      resolve();
    })
      .then(() => this.#fetch_request(url, data))
      .finally(() => this.#stopSpinner());
  }

  get_product_parameters(data) {
    url = '/transfer_transactions/get_product_parameters'
    return this.#fetch_request(url, data);
  }

  get_calculated_rates(data) {
    url = '/transfer_transactions/get_calculated_rates'
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 500); // delayed to fix spinner loading issue
    })
      .then(() => this.#startSpinner())
      .then(() => this.#fetch_request(url, data))
      .finally(() => this.#stopSpinner());
  }

}

class FormUpdateHelper extends FormFetchDataHelper {
  /**
   * Resets Value of the respective element
   * @param {JQuery<HTMLElement>} j_element
   */
  reset_element(j_element) {
    j_element.val('');
  }

  /**
   *
   * @param {JQuery<HTMLElement>} el
   */
  resetErrors(el) {
    el.removeClass('is-invalid');
    el.parent().children('.invalid-feedback').remove();
  }

  /**
   *
   * @param {JQuery<HTMLElement>} el
   */
  setValue(el, value) {
    el.val(value);
    this.resetErrors(el);
  }

  /**
   * Populates a given select element
   * @param {JQuery<HTMLElement>} j_element
   * @param {any[]} options
   */
  populate_select(j_element, options, delivery_type = false) {

    // get last selected value
    const last_value = j_element.val();
    // reset element
    j_element.empty();
    if (options.length !== 1) j_element.html("<option value=''>Please select</option>");

    let selected_value = last_value;
    // incase of single option, selected value will be over-ridden
    if (options.length === 1) selected_value = options[0].id;

    for (let index = 0; index < options.length; index++) {
      const option = options[index];
      const optionValue = delivery_type ? option : option.id
      const optionText = delivery_type ? option : option.name
        ? option.name
        : option.first_name && option.last_name
          ? `${option.first_name} ${option.last_name}`
          : option.first_name
            ? option.first_name
            : option.surname
              ? option.surname
              : "";
      const selected = selected_value == optionValue ? "selected" : "";
      if (selected == 'selected') this.resetErrors(j_element);
      j_element.append(`<option value="${optionValue}" ${selected} > ${optionText} </option>`);
    }

    // options.forEach((option) => {
    //   const optionValue = delivery_type ? option : option.id
    //   const optionText = delivery_type ? option : option.name
    //     ? option.name
    //     : option.first_name && option.last_name
    //       ? `${option.first_name} ${option.last_name}`
    //       : option.first_name
    //         ? option.first_name
    //         : option.surname
    //           ? option.surname
    //           : "";
    //   const selected = selected_value == optionValue ? "selected" : "";
    //   j_element.append(`<option value="${optionValue}" ${selected} > ${optionText} </option>`);
    // });
  }
  fixDateFormat(dateTime) {
    if (dateTime) {
      const isoDate = dateTime;
      const date = new Date(isoDate);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }
  fillFormFields(currentClass, cValues) {
    $(`${currentClass}_first_name`).val(cValues.first_name);
    $(`${currentClass}_middle_name`).val(cValues.middle_name);
    $(`${currentClass}_last_name`).val(cValues.last_name);
    $(`${currentClass}_surname`).val(cValues.surname);
    $(`${currentClass}_primary_cell_number`).val(cValues.primary_cell_number);
    $(`${currentClass}_additional_cell_number`).val(
      cValues.additional_cell_number
    );
    $(`${currentClass}_primary_address`).val(cValues.primary_address);
    $(`${currentClass}_secondary_address`).val(cValues.secondary_address);
    $(`${currentClass}_date_of_birth`).val(cValues.date_of_birth);
    $(`${currentClass}_occupation`).val(cValues.occupation);
    $(`${currentClass}_nationality_id`).val(cValues.nationality_id);
    $(`${currentClass}_country_id`).val(cValues.country_id);
    $(`${currentClass}_primary_cell_no_code`).val(cValues.primary_cell_no_code);
    $(`${currentClass}_additional_cell_no_code`).val(
      cValues.additional_cell_no_code
    );
    $(`${currentClass}_identification_type_id`).val(
      cValues.identification_type_id
    );

    $(`${currentClass}_id_issue_date`).val(this.fixDateFormat(cValues.id_issue_date));
    $(`${currentClass}_id_expiry_date`).val(
      this.fixDateFormat(cValues.id_expiry_date)
    );

    $(`${currentClass}_id_issue_at`).val(cValues.id_issue_at);
    $(`${currentClass}_id_no`).val(cValues.id_no);
    $(`${currentClass}_city`).val(cValues.city);
    $(`${currentClass}_customer_type`).val(cValues.customer_type);
    $(`${currentClass}_source_of_income_id`).val(cValues.source_of_income_id);
    $(`${currentClass}_gender`).val(cValues.gender);
  }

  fill_identities_values(id, cValues) {
    const currentTarg = id == "customer" ? "customer_select" : "bene_select";
    const currentClass = `#transfer_transaction_${id}_attributes`;

    if (
      $(`#${currentTarg}`).val() !== "" &&
      $(`#${currentTarg}`).val() !== "Please select"
    ) {
      this.fillFormFields(currentClass, cValues);
    } else {
      this.fillFormFields(currentClass, {});
    }
  }

  /**
   * Enables/Disables input based on parameter settings
   * @param {any[]} product_parameters Product parameters data
   */
  render_parameter(product_parameters = []) {
    product_parameters.forEach(param => {
      const $field = $(`#${param.field_name}, .${param.field_name}`);
      const isVisibleInSend = param.visible_in_send === true;
      const isBankField = param.field_name === "bank";
      const isBranchField = param.field_name === "branch";

      customToggle($field, isVisibleInSend);
      if (param.field_name !== "country") $field.find("input, select").prop("disabled", !isVisibleInSend);

      if (isBankField) {
        if (isVisibleInSend) {
          customToggle($(".bank-dropdown"), !param.is_bank_editable);
          customToggle($(".bank-name"), param.is_bank_editable);
          $("#bank-name-field").prop("disabled", !param.is_bank_editable);
        } else {
          $(".bank-dropdown, .bank-name").hide()
        }
      }

      if (isBranchField) {
        if (isVisibleInSend) {
          customToggle($(".branch-dropdown"), !param.is_branch_editable);
          customToggle($(".branch-name"), param.is_branch_editable);
          $("#branch-name-field").prop("disabled", !param.is_branch_editable);
        } else {
          $(".branch-dropdown, .branch-name").hide()
        }
      }
      // console.log($field);
      $field.find("label").text(param.generated_label.replace(/_/g, ' '))

      $field.find(".required").removeClass('required');
      $field.find(".required").removeClass('is-invalid');
      if (param.mandatory_in_send) {
        $field.find("label").addClass('required');
        var inputField = $field.find("input, select, textarea");

        if (!inputField.val()) {
          inputField.addClass('is-invalid');
        } else {
          inputField.removeClass('is-invalid');
        }
      }

      // const field_id = $field.attr('id');
      // const field_label = $('label[for="' + field_id + '"]')
      // if (field_label.length > 1) field_label.text(param.generated_label)
    });
  }

  /**
   * Resets last applied parameter settings
   * @param {JQuery<HTMLElement>[]} j_elements fields to be reset
   */
  reset_parameters(j_elements = []) {
    j_elements.forEach((el) => {
      // if (el.attr('id') && !el.attr('id').includes('swift_ifsc')) {
      el.prop('disabled', false);
      // }
      customToggle(el.parent(), true);
      el.siblings('label').find('.mandatory').remove();
    });
  }
}

function createUrlParams(paramsObject) {
  const params = new URLSearchParams();

  for (const key in paramsObject) {
    if (paramsObject.hasOwnProperty(key)) {
      params.append(key, paramsObject[key]);
    }
  }

  return params.toString();
}

/**
 * Resets last applied parameter settings
 * @param {JQuery<HTMLElement>} el fields to be reset
 */
function customToggle(el, toggle) {
  if (toggle) el.css('display', 'flex');
  else el.css('display', 'none');
}


/**
 * Function that returns a Promise to parse JSON data using a web worker
 * @param {string} jsonData JSON string to be parsed
 * @returns {Promise<any[]>}
 */
function parseJSONWithWorker(jsonData) {
  return new Promise((resolve, reject) => {
    const jsonWorker = new Worker('/javascripts/workers/jsonWorker.js');

    // Listen for messages from the worker
    jsonWorker.onmessage = (event) => {
      const parsedData = event.data;
      resolve(parsedData); // Resolve the Promise with parsed data
      jsonWorker.terminate(); // Terminate the worker after resolving
    };

    // Handle errors from the worker
    jsonWorker.onerror = (error) => {
      reject(error); // Reject the Promise with the error
      jsonWorker.terminate(); // Terminate the worker on error
    };

    // Start the worker by sending JSON data
    jsonWorker.postMessage(jsonData);
  });
}

/**
 *
 * @param {JQuery<HTMLElement>} el
 * @param {string} label
 */
function setCountryDialingCode(el, value) {
  // el.val(el.children('option').filter(function () {
  //   const regex = new RegExp(`^${label}`)
  //   return !!$(this).text().toLowerCase().match(regex);
  // }).val());
  el.val(value);
}

class TransactionForm extends FormUpdateHelper {
  /** @type {TransactionForm} */
  static #instance;

  /** Form Fields */
  #agent = $('#transaction-agent');
  #transfer_country = $('#transfer-to');
  #transfer_type = $('#transfer-type');
  #bene_currency = $('#bene-currencies');
  #product = $('#transaction-products');
  #bank = $('#transaction-bank');
  #branch = $('#transaction-branch');
  #bank_name_field = $('#bank-name-field');
  #branch_name_field = $('#branch-name-field');
  #paying_agent = $('#transaction-paying-agent');
  #paying_agent_sub_agent = $('#transaction-sub-agent');
  #pickup_center = $('#transaction-pickup-center');
  #accunt_input = $('#account-input');
  #swift_input = $('#swift_ifsc-input');
  #location_details = $('#location-details-inputs');
  #helpline_details = $('#helpline-details-inputs');
  #single_txn_limit = $('#single-txn-limit-input');
  #sub_agent_address = $("#sub-agent-address-input");
  #sub_agent_contact_no = $("#sub-agent-contact-no-input");

  // calculated
  #ltd_range = $(".ltd-rate")
  #ltd = $('#ltd');
  #dtl = $('#dtl');
  #lc_amount = $('#lc-amount');
  #backend_charge = $('#backend-charge');
  #bene_amount = $('#bene-amount');
  #transfer_amount = $('#transfer-amount');
  #charge_amount = $('#charge-amount');
  #discount = $("#discount")
  #total_recievable = $('#total-recievable');
  #vat_amount = $('#vat-amount');
  #recievable_vat = $('#recievable-vat');
  #lc_currency_code = $(".local-currency-code")
  #ds_currency_code = $(".dest-currency-code")
  #total_receiveable = $("#total-recievable")

  // customer fields
  #customer_type = $("#customer-type");
  #customer_first_name = $("#transfer_transaction_customer_attributes_first_name")
  #customer_middle_name = $("#transfer_transaction_customer_attributes_middle_name")
  #customer_last_name = $("#transfer_transaction_customer_attributes_last_name")
  #customer_sur_name = $("#transfer_transaction_customer_attributes_surname")
  #customer_gender = $("#transfer_transaction_customer_attributes_gender")
  #customer_date_of_birth = $("#transfer_transaction_customer_attributes_date_of_birth")
  #customer_nationality = $("#transfer_transaction_customer_attributes_nationality_id")
  #customer_primary_cell_no_code = $("#transfer_transaction_customer_attributes_primary_cell_no_code")
  #customer_primary_cell_number = $("#transfer_transaction_customer_attributes_primary_cell_number")
  #customer_additional_cell_no_code = $("#transfer_transaction_customer_attributes_additional_cell_no_code")
  #customer_additional_cell_number = $("#transfer_transaction_customer_attributes_additional_cell_number")
  #customer_country = $("#transfer_transaction_customer_attributes_country_id")
  #customer_occupation = $("#transfer_transaction_customer_attributes_occupation")
  #customer_source_of_income = $("#transfer_transaction_customer_attributes_source_of_income_id")
  #customer_primary_address = $("#transfer_transaction_customer_attributes_primary_address")
  #customer_secondary_address = $("#transfer_transaction_customer_attributes_secondary_address")
  #customer_id_issue_at = $("#transfer_transaction_customer_attributes_id_issue_at")
  #customer_id_type = $("#transfer_transaction_customer_attributes_identification_type_id")
  #customer_id_issue_date = $("#transfer_transaction_customer_attributes_id_issue_date")
  #customer_id_expiry_date = $("#transfer_transaction_customer_attributes_id_expiry_date")
  #customer_id_no = $("#transfer_transaction_customer_attributes_id_no")
  #customer_city = $("#transfer_transaction_customer_attributes_city")
  #customer_soi = $("#transfer_transaction_customer_attributes_source_of_income_id")
  #customer_pot = $("#transfer_transaction_customer_attributes_purpose_of_transfer_id")

  // bene fields
  #bene_dropdown = $("#bene_select")
  #bene_first_name = $("#transfer_transaction_bene_attributes_first_name")
  #bene_middle_name = $("#transfer_transaction_bene_attributes_middle_name")
  #bene_last_name = $("#transfer_transaction_bene_attributes_last_name")
  #bene_sur_name = $("#transfer_transaction_bene_attributes_surname")
  #bene_gender = $("#transfer_transaction_bene_attributes_gender")
  #bene_date_of_birth = $("#transfer_transaction_bene_attributes_date_of_birth")
  #bene_nationality = $("#transfer_transaction_bene_attributes_nationality_id")
  #bene_primary_cell_no_code = $("#transfer_transaction_bene_attributes_primary_cell_no_code")
  #bene_primary_cell_number = $("#transfer_transaction_bene_attributes_primary_cell_number")
  #bene_additional_cell_no_code = $("#transfer_transaction_bene_attributes_additional_cell_no_code")
  #bene_additional_cell_number = $("#transfer_transaction_bene_attributes_additional_cell_number")
  #bene_country = $("#transfer_transaction_bene_attributes_country_id")
  #bene_occupation = $("#transfer_transaction_bene_attributes_occupation")
  #bene_source_of_income = $("#transfer_transaction_bene_attributes_source_of_income_id")
  #bene_primary_address = $("#transfer_transaction_bene_attributes_primary_address")
  #bene_secondary_address = $("#transfer_transaction_bene_attributes_secondary_address")
  #bene_id_issue_at = $("#transfer_transaction_bene_attributes_id_issue_at")
  #bene_id_no = $("#transfer_transaction_bene_attributes_id_no")
  #bene_city = $("#transfer_transaction_bene_attributes_city")

  #consider_transfer_amount = $('#consider_transfer_amount')

  // #search_customer_url = $('.search_customer_url')
  // #search_beneie_url = $('.search_beneie_url')

  // class members
  currencies = []
  CvLtd;
  ltdMax;
  agentNetwork
  branches = []

  /**
   *
   * @param {JQuery<HTMLElement>} el
   */
  set_type_ahead_events(el) {
    const class_ref = this;
    // const input = el.next();
    // const select_list = input.next();
    const populate_type_ahead = (el, list) => {
      el.empty();
      for (let index = 0; index < list.length; index++) {
        const branch = list[index];
        el.append(`<div class="w-full px-4 py-2 type_ahead_select_item" data-value="${branch.id}"> ${branch.name} </div>`);
      }
    };

    el.next().on('input', function () {
      if (class_ref.branches.length < 1) {
        class_ref.reset_type_ahead(el, false, 'No Branches Found');
        return;
      }
      const input = $(this);
      const select_input = input.prev();
      select_input.val('');
      const val = input.val().toLowerCase();
      if (val.length < 1) {
        class_ref.reset_type_ahead(el, false);
        return;
      }
      const filtered_branches = class_ref.branches.filter((b) => b.name.toLowerCase().includes(val));
      const select_list = input.next();
      select_list.children('.placeholder').toggle(filtered_branches.length < 1);
      if (filtered_branches.length < 1) class_ref.reset_type_ahead(el, false, 'No Branches Found');
      else populate_type_ahead(select_list, filtered_branches);
    });
  }

  /**
   *
   * @param {JQuery<HTMLElement>} el
   */
  reset_type_ahead(el, reset = true, text = 'Please Type To Filter Results') {
    el.val('');
    const input = el.next();
    if (reset) input.val('');
    input.next().empty().append(`<div class="px-4 placeholder"> ${text} </div>`);
  }

  static getInstance() {
    if (!this.#instance) this.#instance = new TransactionForm();
    return this.#instance;
  }

  // update_customer_search_url() {
  //   const url = this.#search_customer_url.attr('href').split('?')[0];
  //   this.#search_customer_url.attr('href', url + '?' + createUrlParams({
  //     agent: this.#agent.val(),
  //     first_name: this.#customer_first_name.val(),
  //     last_name: this.#customer_last_name.val(),
  //     primary_cell_number: this.#customer_primary_cell_number.val(),
  //   }));
  // }
  // update_beneie_search_url() {
  //   const url = this.#search_beneie_url.attr('href').split('?')[0];
  //   this.#search_beneie_url.attr('href', url + '?' + createUrlParams({
  //     agent: this.#agent.val(),
  //     transfer_to: this.#transfer_country.val(),
  //     first_name: this.#bene_first_name.val(),
  //     last_name: this.#bene_last_name.val(),
  //     primary_cell_number: this.#bene_primary_cell_number.val(),
  //   }));
  //   // console.log('HHHHHHH')
  // }

  #toggleVats(toggle = false) {

    if (!this.#charge_amount.parent().hasClass('hidden')) return;
    console.log('ttttt');

    customToggle(this.#charge_amount.parent(), toggle);
    customToggle(this.#discount.parent(), toggle);
    customToggle(this.#total_recievable.parent(), toggle);
    customToggle(this.#vat_amount.parent(), toggle);
    customToggle(this.#recievable_vat.parent(), toggle);


    const cardHeight = $('#tt-form-value-card').outerHeight();
    if (cardHeight > 0) $('#tt-form-options').css('max-height', cardHeight);
  }

  #form_update_request_data() {
    return {
      agent: this.#agent.val(),
      transfer_country: this.#transfer_country.val(),
      transfer_type: this.#transfer_type.val(),
      bene_currency: this.#bene_currency.val(),
      product: this.#product.val(),
      swift_code: this.#swift_input.val(),
      branch: this.#branch.val(),
      bank: this.#bank.val(),
      sub_paying_agent: this.#paying_agent_sub_agent.val(),
      paying_agent: this.#paying_agent.val(),
      customer_type: this.#customer_type.val(),
      bene_select: this.#bene_dropdown.val()
    };
  }

  #update_assoications(associations = []) {
    const updatedAssociations = associations.map((as, index) => {
      const isLastElement = index === associations.length;
      if (isLastElement) {
        return null;
      }

      if (as.correspondent_associations && as.correspondent_associations.length > 0) {
        return {
          id: as.correspondent_associations[0].id,
          name: as.name
        };
      } else {
        return {
          id: as.id,
          name: as.name
        };
      }
    });
    return updatedAssociations.filter((item) => item !== null);
  }

  /**
   * Get Data updates against user input changes and updates
   * respective form fields
   */
  reflect_updates() {
    return this.get_form_updates(this.#form_update_request_data())
      .then(resp => {
        if (!resp) return;
        this.agentNetwork = resp.agent_network
        this.fill_identities_values("bene", resp.bene_values);
        this.populate_select(this.#transfer_country, resp.transfer_countries || [])
        this.populate_select(this.#transfer_type, resp.delivery_types || [], true)
        this.populate_select(this.#bene_currency, (resp.bene_currencies || []).map((bc) => {
          return { id: bc.id, name: bc.label_with_code };
        }));
        this.currencies = resp.bene_currencies || [];

        this.populate_select(this.#product, resp.products || [])
        this.populate_select(this.#bank, resp.banks || [])
        parseJSONWithWorker(resp.branches || '[]')
          // .then(data => this.populate_select(this.#branch, data))
          .then(data => {
            this.branches = data;
            if (!resp.branch_swift) this.reset_type_ahead(this.#branch);
          })
          .catch(err => console.error(err));
        // this.populate_select(this.#branch, JSON.parse(resp.branches || '[]'))
        this.populate_select(this.#paying_agent, resp.paying_agents || [])
        this.populate_select(this.#paying_agent_sub_agent, resp.sub_agents || [])
        this.populate_select(this.#pickup_center, resp.pickup_centers || [])
        this.populate_select(this.#customer_id_type, this.#update_assoications(resp.identification_types))
        this.populate_select(this.#customer_soi, this.#update_assoications(resp.sois))
        this.populate_select(this.#customer_pot, this.#update_assoications(resp.pots))
        this.#location_details.val(resp.location_details);
        this.#helpline_details.val(resp.help_line);

        const minTrnxLimit = resp.min_trnx || 0;
        const maxTrnxLimit = resp.max_trnx || 0;
        const subAgentAddress = resp.agent_address
        const subAgentContact = resp.agent_phone_no
        this.#single_txn_limit.val(`${minTrnxLimit} - ${maxTrnxLimit}`);
        this.#sub_agent_address.val(subAgentAddress)
        this.#sub_agent_contact_no.val(subAgentContact)

        // hide/show pickup center & paying agent
        if (resp.paying_agent_param) {
          const showPickupCenters = !!resp.pickup_centers;
          customToggle(this.#pickup_center.parent(), showPickupCenters);
          customToggle(this.#paying_agent.parent(), !showPickupCenters);
          customToggle(this.#paying_agent_sub_agent.parent(), !showPickupCenters);
        } else {
          this.#pickup_center.parent().hide();
        }
        if (resp.branch_swift) {
          this.#bank.val(resp.branch_swift.bank_id);
          this.#branch.val(resp.branch_swift.id);
          this.#branch.next().val(resp.branch_swift.name);
          if (resp.branch_swift != this.#swift_input.val()) this.#swift_input.val(resp.branch_swift.swift_code)
        }

        return resp;
      })
      .then(resp => {
        if (!resp) return;
        this.#setCurrencyCode(
          resp.local_currency,
          this.currencies.find((c) => c.id == this.#bene_currency.val()));
        if (this.#transfer_type.val() == "Bank Transfer") {
          if (resp.preferred_product) {
            this.#product.val(resp.preferred_product);
            this.verify_product_parameters();
          }
          else if (!!this.#bene_currency.val()) {
            this.#showAlert('Preferred Product Missing');
            this.reset_element(this.#product);
            customToggle(this.#product.parent(), false);
            return Promise.reject('Preferred product not set');
          };
        }
        return resp;
      });
  }

  verify_product_parameters() {
    const product = this.#product.val();
    const transfer_type = this.#transfer_type.val();
    const customer_type = this.#customer_type.val();
    if (!product || !transfer_type || !customer_type) {
      this.reset_parameters([
        this.#bank, this.#branch, this.#bank_name_field, this.#branch_name_field,
        this.#paying_agent, this.#paying_agent_sub_agent, this.#accunt_input, this.#sub_agent_contact_no,
        this.#pickup_center, this.#swift_input, this.#location_details, this.#sub_agent_address,
        this.#helpline_details, this.#single_txn_limit, this.#customer_middle_name,
        this.#customer_gender, this.#customer_date_of_birth, this.#customer_nationality,
        this.#customer_occupation, this.#customer_source_of_income,
        this.#customer_primary_address, this.#customer_secondary_address, this.#customer_id_type,
        this.#customer_id_issue_date, this.#customer_id_expiry_date, this.#customer_id_no,
        this.#customer_city, this.#customer_pot, this.#bene_first_name, this.#bene_middle_name,
        this.#bene_last_name, this.#bene_primary_address, this.#bene_secondary_address,
        this.#bene_nationality, this.#bene_primary_cell_number, this.#bene_id_no
      ]); // to be filled
      this.#accunt_input.val('');
      this.#swift_input.val('');
      return;
    }


    return this.get_product_parameters({
      product: product,
      transfer_type: transfer_type,
      customer_type: customer_type
    }).then(resp => {
      this.render_parameter(resp.product_parameters)
      this.#toggleSwift()
    });
  }
  #toggleSwift() {
    transferCountryName = this.#transfer_country.find('option:selected').text();
    if (transferCountryName === "India" || this.agentNetwork == true) {
      this.#swift_input.prop('readonly', false);
    } else {
      this.#swift_input.prop('readonly', true);
    }
  }

  #reset_cr_data(j_elements = []) {
    j_elements.forEach((el) => {
      el.val("").prop("disabled", true);
    })
    this.#ltd_range.empty()
  }

  #setCurrencyCode(lc, dc) {
    if (lc) this.#lc_currency_code.html(lc.currency.code);
    else this.#lc_currency_code.html("...");

    if (dc) this.#ds_currency_code.html(dc.currency.code);
    else this.#ds_currency_code.html("...");
  }

  #set_ltd_dtl(ltd, dtl) {
    const selector = $().add(this.#ltd).add(this.#transfer_amount).add(this.#lc_amount).add(this.#dtl)
    if (ltd !== undefined && ltd > 0 && dtl !== undefined && dtl > 0) {
      selector.prop("disabled", false);
      this.#ltd.empty().val(ltd);
      this.#dtl.empty().val(dtl);
    } else {
      selector.prop("disabled", true);
      this.#showAlert("Currency Rates are not defined");
    }
  }

  #set_ltd_dtl_range(cv_ltd, ltdMax) {
    if (cv_ltd && cv_ltd < ltdMax) {
      this.CvLtd = cv_ltd;
      this.ltdMax = ltdMax;
      this.#ltd_range.text(`(${this.CvLtd} - ${ltdMax})`);
    } else if (cv_ltd && cv_ltd >= ltdMax) {
      this.CvLtd = ltdMax;
      this.#ltd_range.text(`(${this.CvLtd} - ${ltdMax})`);
    } else {
      this.#ltd_range.empty();
    }
  }
  #formatToTwoDecimalPlaces(value) {
    return Number(parseFloat(value).toFixed(2));
  }

  #updateInputValues(resp) {
    this.#backend_charge.val("");
    this.#charge_amount.val("");
    this.#bene_amount.val("");

    this.#total_receiveable.val("");
    this.#recievable_vat.val("");
    this.#vat_amount.val("");

    const minAmount = this.#formatToTwoDecimalPlaces(resp.min_amount);
    const maxAmount = this.#formatToTwoDecimalPlaces(resp.max_amount);
    const transferAmount = this.#formatToTwoDecimalPlaces(resp.transfer_amount);
    const local_amount = this.#formatToTwoDecimalPlaces(resp.local_amount);
    const backendCharge = this.#formatToTwoDecimalPlaces(resp.backend_charge);
    const charge = this.#formatToTwoDecimalPlaces(resp.charge);
    // const discount = this.#formatToTwoDecimalPlaces(resp.discount);
    const beneAmount = this.#formatToTwoDecimalPlaces(resp.bene_amount);
    const transferCountryName = this.#transfer_country.find('option:selected').text();
    const totalRecievable = this.#formatToTwoDecimalPlaces(
      resp.total_recievable
    );
    const netTotalRecievable = this.#formatToTwoDecimalPlaces(
      resp.net_total_recievable
    );
    const vatAmount = this.#formatToTwoDecimalPlaces(resp.vat_amount);
    if (minAmount != null && minAmount > 0 && transferAmount < minAmount) {
      this.#showAlert(
        `Transaction FC amount should be between <strong>${minAmount} -
              ${this.#formatToTwoDecimalPlaces(maxAmount)}</strong>`
      );
      // this.#transfer_amount.empty().val(transferAmount);
      this.setValue(this.#transfer_amount, transferAmount);


    } else if (
      maxAmount != null &&
      maxAmount > 0 &&
      transferAmount > maxAmount
    ) {
      this.#showAlert(
        `Transaction FC amount should be between <strong>${minAmount} -
              ${maxAmount}</strong>`
      );
      // this.#transfer_amount.empty().val(transferAmount);
      this.setValue(this.#transfer_amount, transferAmount);
    } else {
      if (
        transferCountryName !== "Pakistan" ||
        transferCountryName !== "India" ||
        transferCountryName !== "Bangladesh"
      ) {
        // this.#backend_charge.val(backendCharge);
        this.setValue(this.#backend_charge, backendCharge);
      }
      // this.#charge_amount.val(charge);
      this.setValue(this.#charge_amount, charge);
      // this.#bene_amount.val(beneAmount);
      this.setValue(this.#bene_amount, beneAmount);
      // this.#total_receiveable.val(totalRecievable);
      this.setValue(this.#total_recievable, totalRecievable);
      this.setValue(this.#discount, discount);
      // this.#recievable_vat.val(netTotalRecievable);
      this.setValue(this.#recievable_vat, netTotalRecievable);
      // this.#vat_amount.val(vatAmount);
      this.setValue(this.#vat_amount, vatAmount);
      if (transferAmount !== null) {
        // this.#lc_amount.val(local_amount)
        this.setValue(this.#lc_amount, local_amount);
      }
      if (local_amount !== null) {
        // this.#transfer_amount.val(transferAmount)
        this.setValue(this.#transfer_amount, transferAmount);
      }
    }
  }
  #showAlert(message) {
    var errors = `
    <ul class="flex flex-wrap gap-x-4 items-center">
      <li>${message}</li>
    </ul>
      `;
    $('#notification-modal .modal-body').html(errors);
    $('#notification-modal-button').click();
  }

  #allPropertiesHaveValue(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (key === 'paying_agent') continue;
        if (obj[key] === undefined || obj[key] === null || obj[key] === '') {
          return false;
        }
      }
    }
    return true;
  }

  #verifyLtdChange(enteredValue) {
    if (!enteredValue || enteredValue === '') this.#ltd.val(this.ltdMax);
    lcAmount = this.#lc_amount.val();
    transferAmount = this.#transfer_amount.val();
    if (enteredValue < this.CvLtd || enteredValue > this.ltdMax) {
      this.#showAlert(
        `Teller Rate should be between <strong>${this.CvLtd} - ${this.ltdMax}</strong>`
      );
      return this.#ltd.val(this.ltdMax);
    }
    this.#dtl.val((1 / enteredValue).toFixed(9));
    if (transferAmount) return this.get_currency_rates({ transfer_amount: transferAmount, ltd: this.#ltd.val(), dtl: this.#dtl.val() }, false);
    if (lcAmount) return this.get_currency_rates({ local_amount: lcAmount, ltd: this.#ltd.val(), dtl: this.#dtl.val() }, false);

  }

  get_currency_rates(c_value = {}, triggerLTD = true) {
    const data = { ...this.#form_update_request_data(), ...c_value };
    delete data.branch;
    delete data.swift_code;
    delete data.bank;
    delete data.bene_select;
    delete data.sub_paying_agent;

    if (!this.#allPropertiesHaveValue(data)) {
      this.#reset_cr_data([
        this.#ltd, this.#dtl, this.#lc_amount, this.#bene_amount,
        this.#backend_charge, this.#transfer_amount, this.#charge_amount,
        this.#total_recievable, this.#vat_amount, this.#recievable_vat
      ]);
      this.ltdMax = undefined;
      this.CvLtd = undefined;
      return;
    }
    if (parseFloat(this.#discount.val()) > parseFloat(this.#charge_amount.val())) {

      this.#showAlert("discount cannot be more than charge")
      this.#discount.val(0).trigger("change")
      this
      return
    }

    this.#toggleVats(true);

    return this.get_calculated_rates(data)
      .then(resp => {
        // console.log(resp)
        if (resp.online_rate_exist == false) {
          this.#showAlert("Fail to fetch online rate");
          return Promise.reject("Fail to fetch online rate");
        }
        ltd = resp.ltd
        this.ltdMax = resp.ltd;
        dtl = resp.dtl;
        local_amount = resp.local_amount
        transfer_amount = resp.transfer_amount
        discount = resp.discount || 0
        if (this.#transfer_amount.val() > 0 || this.#lc_amount.val() > 0) {
          this.#discount.prop("disabled", false)
        } else {
          this.#discount.prop("disabled", true)
        }
        console.log(dtl)
        if (ltd && dtl) {

          if (triggerLTD) this.#set_ltd_dtl(ltd, dtl)
        } else {
          this.#reset_cr_data([
            this.#ltd, this.#dtl, this.#lc_amount, this.#bene_amount,
            this.#backend_charge, this.#transfer_amount, this.#charge_amount,
            this.#total_recievable, this.#vat_amount, this.#recievable_vat, this.#discount
          ]);
          const selector = $().add([this.#ltd, this.#transfer_amount, this.#lc_amount, this.#discount]);
          selector.prop("disabled", true);
          this.#showAlert("Currency Rates are not defined");
        }
        if (triggerLTD) {
          this.#set_ltd_dtl_range(resp.cv_ltd, this.ltdMax)
        }
        if (local_amount || transfer_amount || discount) {
          this.#updateInputValues(resp)
        }

      });
  }


  register_change_events() {
    const class_ref = this;
    // reset errors on change
    $('.form-control').on('input change', function () {
      const input = $(this);
      class_ref.resetErrors(input);
    });

    this.#agent.on('change', () => {
      this.#transfer_country.val(' ')

      this.reset_element(this.#transfer_country);
      this.reset_element(this.#bene_currency);
      this.reset_element(this.#product);
      this.reflect_updates()
        .then(() => this.verify_product_parameters())
        .then(() => this.get_currency_rates())
    });


    this.#transfer_country.on('change', ({ target }) => {
      this.#transfer_type.val(' ')
      transferCountryName = this.#transfer_country.find('option:selected').text();

      // console.log(transferCountryName)
      if (
        transferCountryName === "Pakistan" ||
        transferCountryName === "India" ||
        transferCountryName === "Bangladesh"
      ) {
        this.#backend_charge.parent().hide();
      } else {
        this.#backend_charge.parent().show();
      }



      this.reset_element(this.#bene_currency);
      this.reset_element(this.#product);
      this.reflect_updates()
        .then((resp) => {
          setCountryDialingCode(this.#bene_primary_cell_no_code, resp.dialing_code);
          setCountryDialingCode(this.#bene_additional_cell_no_code, resp.dialing_code);
        })
        .then(() => this.verify_product_parameters())
        .then(() => this.get_currency_rates())
        .then(() => {
          this.#bene_nationality.val(target.value);
          this.#bene_country.val(target.value);
        });
    });
    this.#transfer_type.on('change', () => {
      this.reset_element(this.#product);
      this.reflect_updates()
        .then(() => this.verify_product_parameters())
        .then(() => {
          const showProduct = 'Bank Transfer' != this.#transfer_type.val();
          customToggle(this.#product.parent(), showProduct);
        })
        .then(() => this.get_currency_rates());
    });
    this.#bene_currency.on('change', () => {
      this.reset_element(this.#product);
      this.reflect_updates()
        .then(() => this.verify_product_parameters())
        .then(() => this.get_currency_rates());
    });
    this.#product.on('change', () => {

      this.reflect_updates()
        .then(() => this.verify_product_parameters())
        .then(() => this.get_currency_rates());
    });

    this.#customer_type.on('change', () => this.verify_product_parameters());
    this.#bank.on('change', () => {
      this.#branch.val('');
      this.#swift_input.val('');
      return this.reflect_updates()
        .then(() => this.verify_product_parameters());
    });

    this.#bene_dropdown.on('change', () => {
      // let beneSelected = this.#bene_dropdown.val()
      this.reflect_updates()
    })

    this.set_type_ahead_events(this.#branch);
    this.#swift_input.on('change', () => this.reflect_updates());
    this.#branch.on('change', () => {
      this.#swift_input.val('');
      return this.reflect_updates()
    });

    this.#lc_amount.on('change', ({ target }) => this.get_currency_rates({ local_amount: target.value, ltd: this.#ltd.val(), dtl: this.#dtl.val() }, false));
    this.#transfer_amount.on('change', ({ target }) => this.get_currency_rates({ transfer_amount: target.value, ltd: this.#ltd.val(), dtl: this.#dtl.val() }, false));
    this.#discount.on('change', ({ target }) => this.get_currency_rates({ discount: target.value, local_amount: this.#lc_amount.val(), transfer_amount: this.#transfer_amount.val(), ltd: this.#ltd.val(), dtl: this.#dtl.val() }, false));
    this.#ltd.on('change', ({ target }) => this.#verifyLtdChange(target.value));
    this.#paying_agent.on('change', () => {
      return this.reflect_updates()
        .then(() => {
          const data = {};
          let amount = this.#lc_amount.val();
          if (amount) data.local_amount = amount;
          amount = this.#transfer_amount.val();
          if (amount) data.local_amount = amount;
          return this.get_currency_rates(data);
        });
    });
    this.#paying_agent_sub_agent.on('change', () => {
      return this.reflect_updates()
    })
    // $().add(this.#agent).add(this.#customer_first_name).add(this.#customer_last_name).add(this.#customer_primary_cell_number)
    //   .on('change', () => this.update_customer_search_url())

    // $().add(this.#agent).add(this.#transfer_country).add(this.#bene_first_name).add(this.#bene_last_name).add(this.#bene_primary_cell_number)
    //   .on('change', () => this.update_beneie_search_url())

    let amount_input_fields = this.#lc_amount.add(this.#transfer_amount);
    amount_input_fields.on("keydown", function (event) {
      if (event.key === "Enter" || event.key === "Tab") return;
      const value = this.id === 'transfer-amount';
      class_ref.#consider_transfer_amount.val(value.toString());
    });
  }
}


$(() => {
  const instance = TransactionForm.getInstance();
  instance.register_change_events();
  // instance.update_customer_search_url();
  // instance.update_beneie_search_url();

  const urlParams = new URLSearchParams(window.location.search);
  const ptcn_no = urlParams.get("ptcn_no");
  if (ptcn_no) {
    instance.reflect_updates()
      .then(() => instance.verify_product_parameters())
      .then(() => instance.get_currency_rates());
  }

  $('#new_transfer_transaction').on('submit', function () {
    $('#spinner-modal-trigger').trigger('click');
  });

  const $type_ahead_input = $('.type_ahead_input');
  $type_ahead_input.on('focus', function () {
    const input = $(this);
    // input.prev().val('');
    input.next().addClass('active');
  });
  $type_ahead_input.on('blur', function () {
    const input = $(this);
    setTimeout(() => {
      input.next().removeClass('active');
    }, 300);
  });


  $('.type_ahead_select_list').on('click', function (event) {
    const item = $(event.target);
    if (!item.hasClass('type_ahead_select_item')) return;
    const val = item.attr('data-value');

    const parent = item.parent().parent();
    parent.children('.type_ahead_input').val(item.text().trim());
    parent.children('.type_ahead_select_list').empty().append('<div class="px-4 placeholder"> Please Type To Filter Results </div>');
    parent.children('.type_ahead_select_value').val(val).trigger('change');
  });

});

$(document).ready(function () {
  $(".clear-bene").on("click", function (event) {
    event.preventDefault();
    $("#bene_render").find("input, textarea").val("");
    // $("#bene_render").find("select").prop('selectedIndex', 0);
    $("#bene_render").find("select").prepend("<option value=''>Please select</option>").prop('selectedIndex', 0);
  });
  $('input, select, textarea').each(function () {
    if ($(this).val()) {
      $(this).removeClass('is-invalid');
    }
  });


});
